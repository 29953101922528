<template>
    <section id="multiselect-professionals">
        <b-form-group>
            <label v-if="label">{{ label }}</label>
            <multiselect
                :max="max || 3"
                label="name"
                track-by="id"
                :value="selectedSchedules"
                :options="schedules"
                :close-on-select="false"
                :option-height="40"
                :showLabels="false"
                :searchable="true"
                :multiple="true"
                :disabled="disabled"
                :placeholder="placeholder ? placeholder : 'Selecionar'"
                openDirection="bottom"
                class="with-border multiple select-container"
                @input="handleSelectedSchedules"
                
            >
                <template slot="caret">
                    <div class="chevron">
                        <ChevronDown />
                    </div>
                </template>
                <template slot="selection" slot-scope="{ values }">
                    <div class="avatar-wrapper" v-for="option in values" :key="option.id">
                        <Avatar 
                            class="avatar" 
                            size="25" 
                            :src="option?.picture" 
                            v-b-tooltip.hover :title="option.name"
                        />
                    </div>
                </template>
                <template slot="noOptions">Nenhuma opção</template>
                <template slot="noResult">Nenhum resultado</template>
                <template slot="maxElements">
                    Você só pode selecionar até {{ max }} agendas simultaneamente. <a class="clear" @click="setSelectedSchedules([])">Limpar seleção</a> 
                </template>
            </multiselect>
        </b-form-group>
    </section>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { userHasRole, getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
export default {
    name: 'MultiSelectProfessionals',
    components: { 
		ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
        Avatar: () => import('@/components/General/Avatar'),
	},
    props: {
        label: String,
        max: Number,
        disabled: Boolean,
        placeholder: String,
    },
    async created() {
        !this.schedules.length && this.getScheduleOptions(this.clinic.id)
	},
    computed: {
        ...mapState('schedule', ['schedules', 'selectedSchedules']),
    },
    data() {
        return {
            clinic: getCurrentClinic(),
            options: [],
            professionals: [],
        }
    },
    methods: {
        userHasRole,
        ...mapActions('schedule', ['getScheduleOptions', 'setSelectedSchedules']),
        handleSelectedSchedules(options){
            const clinic = getCurrentClinic();
            const user = getCurrentUser();

            options = options.map(function(item){
                item.clinicId = clinic.id;
                item.userId = user.id;
                return item;
            });

            this.setSelectedSchedules(options)
        }
    }
}
</script>
<style lang="scss">
#multiselect-professionals {
    .form-group {
        margin-bottom: 0 !important;
    }
    .multiselect {
        margin-bottom: 0;
        .multiselect__tags {
            width: 100%;
            height: 38px;
            border: 1px solid var(--neutral-300);
        }
        .multiselect__placeholder {
            margin-right: 1rem;
        }
    }
    .avatar-wrapper {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
    }
    .avatar {
        display: flex;
        align-items: center;
        margin: 0 3px 0 0 !important;
    }
}
</style>
<style lang="scss" scoped>
.clear {
    font-weight: 500;
    cursor: pointer;
}
</style>